import React from 'react';
//import logo from './logo.svg';
import logo from './0658.svg'

import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo-" alt="logo" />
        <p>
          Er Vålerenga
        </p>


        <ul>
          <li class="male"><a
          className="App-link"
          href="https://www.vif-hockey.no/"
          target="_blank"
          rel="noopener noreferrer"
        >Vålerenga hockey
        </a></li>
        <li class="male">
        <a
          className="App-link"
          href="https://www.vif-fotball.no/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Vålerenga fotball
        </a>
        </li>
        <li class="female">
        <a
          className="App-link"
          href="http://vif-damefotball.no/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Vålerenga fotball
        </a>
        </li>
        <li className="both">
        <a
          className="App-link"
          href="https://klanen.no"
          target="_blank"
          rel="noopener noreferrer"
        >
          Klanen
        </a>
        </li>
        <li className="both">
        <a
          className="App-link"
          href="https://www.vpn.no/"
          target="_blank"
          rel="noopener noreferrer"
        >
          VPN
        </a>
        </li>
        
        </ul>
      </header>
    </div>
  );
}

export default App;
